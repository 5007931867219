import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { FileUpload } from '@app/core/models/fileupload';
import S3, { ManagedUpload } from 'aws-sdk/clients/s3';
import { Observable, of as observableOf } from 'rxjs';


const imageBucket = environment.imageBucket;
const videoBucket = environment.videoBucket;
// const docUrl = environment.docUrl;
const bucketUrl = environment.docUrl;
const videoBucketUrl = environment.videoUrl;
const teamMemberFolder = environment.teamMemberFolder;
const businessUnitFolder = environment.businessUnitFolder;
const storeFolder = environment.storeFolder;
const taxonomyBanner = environment.taxonomyBanner;
const taxonomyThumbnail = environment.taxonomyThumbnail;
const taxonomyIcon = environment.taxonomyIcon;
const productFolder = environment.productFolder;
// const fitInPrefix = environment.fitInPrefix;
// const small_image = environment.small_image;
// const medium_image = environment.medium_image;
// const large_image = environment.large_image

@Injectable({
  providedIn: 'root'
})
export class UploaderService {
  videoBucketUrl = videoBucketUrl;
  bucketUrl = bucketUrl;
  teamMemberFolder = teamMemberFolder;
  businessUnitFolder = businessUnitFolder;
  storeFolder = storeFolder;
  taxonomyBanner = taxonomyBanner;
  taxonomyThumbnail = taxonomyThumbnail;
  taxonomyIcon = taxonomyIcon;
  productFolder = productFolder;

  mediaRoot = '';

  constructor() {

  }

  bucket = new S3(
    {
      accessKeyId: `${environment.s3Acess.accessKeyId}`,
      secretAccessKey: `${environment.s3Acess.secretAccessKey}`,
    }
  );

  generaeFileName(namePrefix: string, fName: string): string {
    return Math.floor(Math.random() * 100000000) + namePrefix + fName;
  }

  uploadFile(file: any, fileName: any, isImageUpload: boolean, imageModule?: any): ManagedUpload {
    const contentType = file.type;
    const params = {
      Bucket: isImageUpload ? imageBucket : videoBucket,
      Key: '',
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    switch (imageModule) {
      case 'businessUnit':
      case 'user':
      case 'store':
        params.Key = `party/${fileName}`;
        break;
      case 'taxonomyBanner':
      case 'taxonomyThumbnail':
      case 'taxonomyIcon':
        params.Key = `taxonomy/${fileName}`;
        break;
      case 'products':
        params.Key = `items/${fileName}`;
        break;
      case 'cms':
        params.Key = `cms/${fileName}`;
        break;
      case 'review':
        params.Key = `review/${fileName}`;
        break;
      case 'cms-form-files':
        params.Key = `cms-form-files/${fileName}`;
      break;
      default:
        params.Key = `${fileName}`;
        break;
    }
    // for upload progress.
    return this.bucket.upload(params);
  }

  async fileExists(fileName: any, isImageUpload: boolean, imageModule: string) {
    const params = {
      Bucket: isImageUpload ? imageBucket : videoBucket,
      Key: '',
    };

    switch (imageModule) {
      case 'businessUnit':
      case 'user':
      case 'store':
        params.Key = `party/${fileName}`;
        break;
      case 'taxonomyBanner':
      case 'taxonomyThumbnail':
      case 'taxonomyIcon':
        params.Key = `taxonomy/${fileName}`;
        break;
      case 'products':
        params.Key = `items/${fileName}`;
        break;
      case 'cms':
        params.Key = `cms/${fileName}`;
        break;
      case 'review':
        params.Key = `review/${fileName}`;
        break;
      case 'cms-form-files':
        params.Key = `cms-form-files/${fileName}`;
        break;
      default:
        params.Key = `${fileName}`;
        break;
    }
    const data = await this.bucket.getObject(params).promise();
    return data;
  }

  getSignUrl(key: any, isImageUpload: boolean) {
    let params_2 = {
      Bucket: isImageUpload ? imageBucket : videoBucket,
      Key: key,
    };
    let signedUrl: any = '';
    signedUrl = this.bucket.getSignedUrl('getObject', params_2);
    return signedUrl;
  }

  upLoadFileInFolder(file: any, folderName: any, file_name: any, isImageUpload: boolean) {
    const contentType = file.type;
    const params = {
      Bucket: isImageUpload ? imageBucket : videoBucket,
      Key: folderName + '/' + file_name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    //for upload progress
    return this.bucket.upload(params);
  }

  deleteFolder(file: any, folderPrefix: any, isImageUpload: boolean) {
    const contentType = file.type;
    const params = {
      Bucket: isImageUpload ? imageBucket : videoBucket,
      Key: folderPrefix,
    };
    //for upload progress
    return this.bucket.deleteObject(params);
  }

  getAllFiles(isImageUpload: boolean): Observable<Array<FileUpload>> {
    const fileUploads = new Array<FileUpload>();
    const params = {
      Bucket: isImageUpload ? imageBucket : videoBucket
      //Prefix: this.FOLDER
    };

    this.bucket.listObjects(params, function (err, data) {
      if (err) {
        return;
      }
      const fileDatas = data.Contents;
      fileDatas.forEach(function (file) {
        const name = file.Key
        const url = bucketUrl + file.Key
        fileUploads.push({ name, url });
      });
    });
    // return Observable.of(fileUploads);
    return observableOf(fileUploads);
  }


  deleteFile(file_name: any, isImageUpload: boolean, imageModule?: any) {
    const params = {
      Bucket: isImageUpload ? imageBucket : videoBucket,
      Key: `${imageModule}/${file_name}`
    };

    this.bucket.deleteObject(params, function (err, data) {
      if (err) {
        return;
      }
    });

    return this.bucket.deleteObject(params);
  }

}
