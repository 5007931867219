import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonService } from '@app/core/services/common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { D } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-color-distribution',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, FormsModule],
  templateUrl: './color-distribution.component.html',
  styleUrls: ['./color-distribution.component.scss']
})
export class ColorDistributionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ColorDistributionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    if (this.data?.swatches.length > 0) {
      if (this.data.data.items && this.data.data.items.length > 0) {
        // Create a mapping from swatch item_id to total quantity in items
        const itemQuantityMap = this.data.data.items.reduce((acc, item) => {
          if (acc[item.item_id]) {
            acc[item.item_id] += item.quantity;
          } else {
            acc[item.item_id] = item.quantity;
          }
          return acc;
        }, {});

        // Update swatch quantities based on the item quantities
        this.data.swatches = this.data.swatches.map(swatch => ({
          ...swatch,
          quantity: itemQuantityMap[swatch.item_id] || 0 // Use the quantity from itemQuantityMap or 0 if not found
        }));
      } else {
        // If there are no items, set default quantity to 0
        this.data.swatches = this.data.swatches.map(swatch => ({
          ...swatch,
          quantity: 0
        }));
      }
    }
  }

    updateQuantity(index: number, change: number) {
      if (this.data?.swatches[index]) {
        const newQuantity = (this.data.swatches[index].quantity || 0) + change;
        this.data.swatches[index].quantity = newQuantity < 0 ? 0 : newQuantity;
      }
    }

    closeDialog() {
      this.dialogRef.close()
    }

    getTotalQuantity(data: any) {
      return data.reduce((total, item) => total + item.quantity, 0);
    }


    submitQuantity() {
      const totalQuantity = this.getTotalQuantity(this.data.swatches);
      const count = this.data.swatches.filter(product => product.quantity > 0).length;
      let isMaxColorPerPalletQty = count <= this.data.maxColorPerPallet;
      if(!isMaxColorPerPalletQty){
        this.commonService.openSnackbar(`You can choose only ${this.data.maxColorPerPallet} color per pallet.`);
      }
      else if (totalQuantity < this.data.setsPerPallet) {
        this.commonService.openSnackbar(`Minimum of ${this.data.setsPerPallet} sets required.`);
      } else if (totalQuantity > this.data.setsPerPallet) {
        this.commonService.openSnackbar(`Cannot save more than ${this.data.setsPerPallet} sets.`);
      } else {
        this.data.data = { items: this.data.swatches.filter((sw:any)=> sw.quantity>0), totalPalletQuantity: Number(totalQuantity) };
        this.dialogRef.close(this.data)
      }
    }
  }
