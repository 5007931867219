<div class="pop-up-body terms-and-condition show">
    <div class="pop-box plate-count">
        <div class="pop-head">Terms And Condition</div>

        <div class="pop-content" mat-dialog-content>
            <div class="row-cus">
                <div
                    class="lablerow"
                    style="padding: 10px 20px 0px 35px;"
                >
                    <ul>
                        <li>
                            <strong>Pricing FOB:</strong>
                            Port of Origin
                        </li>
                        <li>
                            <strong>Payment Terms:</strong>
                            50% deposit / balance on shipment. Net 30 days from receipt with approved credit.
                        </li>
                        <li>
                            <strong>Additional Charges:</strong>
                            <ul>
                                <li>
                                    Ocean Freight / Customs Clearance & Duty will be added as separate line items on order confirmations and invoices.
                                </li>
                                <li>
                                    Ocean freight costs are estimated on confirmations. Actual freight cost will be billed at time of shipment.
                                </li>
                                <li>
                                    Border Concepts works with several freight forwarders and is confident that we can offer competitive freight rates.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Production Lead Time:</strong>
                            90 – 120 days from order confirmation. Lead times may be longer during peak production season (Sept – January).
                        </li>
                        <li>
                            <strong>Ocean Transit Time:</strong>
                            <ul>
                                <li>West Coast - 14 Days</li>
                                <li>East Coast - 45 Days</li>
                            </ul>
                            Delays in transit are common these days so lead times may get extended 14-21 days.
                        </li>
                        <li>
                            <strong>Breakage Allowance:</strong>
                            Buyer assumes a 3% allowance for breakage. Claims for less than 3% will not be accepted.
                        </li>
                        <li>
                            <strong>Quantity Variance:</strong>
                            Buyer accepts a +/- 5% variance in quantities which may occur due to mis-firings or quality control rejections.
                        </li>
                        <li>
                            <strong>Unloading Time:</strong>
                            1 Free Hour for Unloading. Customer is responsible for extra time for unloading after 1 hour. Truckers usually charge $80+ per hour after 1 hour of free time expires. If the container needs to be dropped and picked up at a later time, the customer is responsible for charges.
                        </li>
                        <li>
                            <strong>Customs Inspections:</strong>
                            Customer is responsible for any charges associated with container exams or inspections at the port by US/Canadian Customs or other government agencies which are out of the control of Border Concepts.
                        </li>
                        <li>
                            <strong>Product Variations:</strong>
                            Due to the handmade nature of the product, there can be variations in color and size within a reasonable range. Customer must accept a reasonable amount of variation in products.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="bottom blk">
            <button
                class="yellow-btn"
                type="button"
                (click)="closeDialog(true)"
            >
                Accept
            </button>
            <button
                class=" primary-base-btn"
                type="button"
                (click)="closeDialog(false)"
            >
                Cancel
            </button>
        </div>
    </div>
</div>
