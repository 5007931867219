import { Component, OnInit, OnDestroy, HostListener, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { AuthService } from '@app/core/services/auth.service';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { BodyLoaderComponent } from '@app/body-loader/body-loader.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    RouterOutlet,
    FooterComponent,
    BodyLoaderComponent,
    NgIf]
})
export class LayoutComponent implements OnInit, OnDestroy {
  isEditor: boolean;
  showLoader: boolean;
  loaderSubscription: any;
  MobileNav: boolean;
  DesktopNav: boolean;
  constructor(public commonService: CommonService, private cdRef: ChangeDetectorRef, private router: Router, private authService: AuthService) {
    this.isEditor = false;
    this.showLoader = false;
    this.MobileNav = false;
    this.DesktopNav = true;
  }

  ngOnInit(): void {

  }
  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
  }
}
