import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import { UrlSerializer, provideRouter, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CustomUrlSerializer } from './custom-url-serializer';
import { routes } from './app.routes';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserStateInterceptor } from './core/services/http.interceptor.browser';
import { InterceptorService } from './core/services/interceptor.service';
import { DataResolverService } from './shared/service/data-resolver.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { MetaService2 } from './core/services/meta.service';
import { provideNgxStripe } from 'ngx-stripe';
import { environment } from 'environments/environment';
import { provideClientHydration } from '@angular/platform-browser';
export const appConfig: ApplicationConfig = {

  providers: [
    provideRouter(routes, withRouterConfig({
      onSameUrlNavigation: 'reload',
    }),
    withInMemoryScrolling({
      scrollPositionRestoration: 'enabled',
    })),
    provideHttpClient(withInterceptorsFromDi()),
    provideClientHydration(),
    importProvidersFrom([MatSnackBarModule,MatDialogModule]),
    provideAnimations(),
    provideNgxStripe(environment.stipePublicKey),
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: BrowserStateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    DataResolverService,
    GoogleAnalyticsService,
    MetaService2
  ],

};
