import { DOCUMENT } from '@angular/common';
import { Injectable, Optional, RendererFactory2, ViewEncapsulation, Inject } from '@angular/core';

@Injectable()
export class MetaService2 {

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document
  ) {
  }

  /**
   * Inject the State into the bottom of the <head>
   */
  addTag(tag: LinkDefinition, forceCreation?: boolean) {
    try {
      const renderer = this.rendererFactory.createRenderer(this.document, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      });

      const head = this.document.head;

      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }

      // Check if a canonical link already exists and remove it
      const existingCanonicalLink = head.querySelectorAll('link[rel="canonical"]');
      for (let i = 0; i < existingCanonicalLink.length; i++) {
        const node = existingCanonicalLink[i];
        node?.remove()
      }

      const link = renderer.createElement('link');

      Object.keys(tag).forEach((prop: string) => {
        return renderer.setAttribute(link, prop, tag[prop]);
      });
      renderer.appendChild(head, link);

    } catch (e) {
      console.error('Error within linkService: ', e);
    }
  }


  private _parseSelector(tag: LinkDefinition): string {
    // Possibly re-work this
    const attr: string = tag.rel ? 'rel' : 'hreflang';
    return `${attr}="${tag[attr]}"`;
  }
  removeTags() {
    const head = this.document.head;
     const existingCanonicalLink = head.querySelectorAll('link[rel="canonical"]');
     for (let i = 0; i < existingCanonicalLink.length; i++) {
       const node = existingCanonicalLink[i];
       node?.remove()
     }
  }
}


export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};
