export const environment = {
  production: true,
  apiCustomRootUrl: 'https://api.bcipotterydirect.com/backend/',
  apiCustomServerRootUrl: 'https://api.bcipotterydirect.com/backend/',
  // apiCustomRootUrl: 'http://192.168.0.208:8000/backend/',
  apiCustomIpAddress: 'https://geolocation-db.com/json/',
  apiRebate: 'https://og.bcipotterys.com/Online/api/',
  customerUrl: 'https://bcipotterydirect.com',
  s3Acess: {
    accessKeyId: 'AKIA5XJA4ADM44H3D5AH',
    secretAccessKey: '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',
    // region: 'us-west-2',
    // signatureVersion: 'v4'
  },
  // Cloudfront bucket.
  imageBucket: 'potterydirect',
  videoBucket: 'potterydirect',
  teamMemberFolder: 'party/',
  businessUnitFolder: 'party/',
  storeFolder: 'party/',
  taxonomyBanner: 'taxonomy/',
  taxonomyThumbnail: 'taxonomy/',
  taxonomyIcon: 'taxonomy/',
  productFolder: 'items/',
  docUrl: 'https://potterydirect.s3.amazonaws.com/',
  imageBaseUrl: 'https://d1if7r1bmiltgp.cloudfront.net/',
  videoUrl: 'https://potterydirect.s3.amazonaws.com/',
  firebaseAccess: {
    apiKey: "",
    authDomain: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: ""
  },
  flipAccess: {
    accessToken: "",
    merchantId: "",
    merchantNameIdentifier: ""
  },
  stipePublicKey: "",
  AWS_S3_XML_FOLDER: "assets/xml",
  isDevServer: true
};
