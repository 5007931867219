<div class="pop-box plate-count">
  <div class="pop-head">
    Add color quantity: Pallet #{{data.pallet}}
    <a (click)="closeDialog()"><i class="icon-cross"></i> </a>
  </div>
  <div class="custom-head">
    <h3>Max color/Pallet: {{data.maxColorPerPallet}}</h3>
    <h3>Min Sets/Color: {{data.minSetsPerColor}}</h3>
    <h3 style="font-weight: bold;">Sets/Pallet: {{data.setsPerPallet}}</h3>
  </div>
  <div class="add-order-quentity-box" *ngIf="data.swatches.length===0">
    <ng-container>
      <span>Please Select Colour</span>
    </ng-container>
  </div>

  <div class="add-order-quentity-box" *ngIf="data.swatches.length>0">
    <div class="addcolor-row" *ngFor="let item of data.swatches; let i = index">
      <div class="left-imgblock">
        <div class="img-block">
          <img alt="" [src]="item.swatch_image">
        </div>
        <p>
          {{item.name}}
          <span>${{item.price}}</span>
        </p>
      </div>
      <div class="count-wrap">
        <a class="btn" (click)="updateQuantity(i, -1)"><i class="icon-minus-sign"></i></a>
        <input class="count-box" type="number" [(ngModel)]="item.quantity" placeholder="0">
        <a class="btn" (click)="updateQuantity(i, 1)"><i class="icon-plus"></i></a>
      </div>
    </div>
  </div>
  <div class="bottom" *ngIf="data.swatches.length>0">
    <button class="primary-btn" (click)="submitQuantity()">Save</button>
    <button class="primary-base-btn" (click)="closeDialog()">Cancel</button>
  </div>
</div>
