import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { isPlatformBrowser, isPlatformServer } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService, @Inject(PLATFORM_ID) private platformId: Object,) { }

  /**
   * Method to intercent http requests.
   * @param req Request parameter;
   * @param next HttpHandler parameter.
   * @returns Observable of HttpEvent.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(window.location.origin)) {
      if (!req.url.includes('selfIPaddress') && !req.url.includes('services') && req.url !== 'http://api.ipify.org/?format=json') {
        if(isPlatformServer(this.platformId)){
          const url = `${environment.apiCustomServerRootUrl}`;
          req = req.clone({
            url: url + req.url
          });
        }else if(isPlatformBrowser(this.platformId)){
          const url = `${environment.apiCustomRootUrl}`;
          req = req.clone({
            url: url + req.url
          });
        }


        const token: string = this.auth.auth;
        if (token && (req.url.includes('rebatecentervalidate') || req.url.includes('giftcardorders') || req.url.includes('customergiftcard') || req.url.includes('consumerchangepassword') || req.url.includes('consumerprofile') || req.url.includes('recentvieweditem') || (req.url.includes('productreviewsratings') && req.method === 'POST') || req.url.includes('mywishlist') || req.url.includes('productreviewsratingsbycustomer') || req.url.includes('productreviewsratingsupdate') || req.url.includes('/cart') || req.url.includes('consumerwishlist') || req.url.includes('customeraddresses') || req.url.includes('customerorderlist') || req.url.includes('stripepayment') || req.url.includes('orderpickupauthorize') || req.url.includes('cuponvalidation') || req.url.includes('customerbillingaddressordersummary') || req.url.includes('stripecardlist') || req.url.includes('billingaddresssave') || req.url.includes('checkpaymentmethod') || req.url.includes('customerstockcheck') || req.url.includes('giftcardpaymentcapture') || req.url.includes('checkout') || req.url.includes('customerrebate') || req.url.includes('rebatecenter') || req.url.includes('uiorderstatelookup') || req.url.includes('pickupslotcheck') || req.url.includes('customernightlyemail') || req.url.includes('rebatelist') || req.url.includes('customerlikeitemlist') || req.url.includes('userconatinersettings') || req.url.includes('customerconatinersettingscart') || req.url.includes('deletecontainers') || req.url.includes('freightretail') || req.url.includes('customershippingdetails') || req.url.includes('recentvieweditem') || req.url.includes('customercartcount') || req.url.includes('customerorderdetails') || req.url.includes('changeorderquantity') || req.url.includes('orderspecification') || req.url.includes('copycart') || req.url.includes('cartlistidentifier') || req.url.includes('orderedit'))) {
          req = req.clone({ headers: req.headers.set('Authorization', 'Token ' + token) });
        }
      }
      else {
        const url = `${environment.apiCustomIpAddress}`;
        req = req.clone({
          url: url
        });
      }
    }
    return next.handle(req);
  }
}


