import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatChipsModule} from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatTabsModule} from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';


// MatInputModule,CommonModule,MatChipsModule,FormsModule,ReactiveFormsModule,MatFormFieldModule,MatIconModule, MatButtonModule, MatTableModule, MatCheckboxModule,MatMenuModule,MatSelectModule,MatPaginatorModule,DataTableComponent,MatSlideToggleModule,
@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule
  ],
  exports:[
    MatSlideToggleModule, MatDialogModule,
    MatInputModule,MatChipsModule,FormsModule,MatExpansionModule,
    ReactiveFormsModule,MatRadioModule,MatFormFieldModule,MatIconModule,
    MatButtonModule, MatTableModule, MatCheckboxModule,MatMenuModule,MatSelectModule,MatPaginatorModule,MatProgressSpinnerModule,MatTabsModule,MatTooltipModule
  ]
})
export class MaterialModule { }
