import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataApiService } from './http/data-api.service';
// import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app';
// import { CommonService } from '@app/core/services/common.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  globalRouterLoaderShow: BehaviorSubject<boolean>;
  globalRouterLoaderShow_: Observable<any>;

  user: string = '';
  userfirstname: string = '';
  userlastname: string = '';
  userId: string = '';
  isLoggedin: string = '';
  auth: string = '';
  username: string = '';
  customer_id: string = '';
  customerorder_id = '';
  phone: string = ''
  rememberAuth: boolean = false;
  bcipotteryToken: string;

  authToken: BehaviorSubject<any> = new BehaviorSubject<any>({ user: '', userfirstname: '', userlastname: '', userId: '', isLoggedin: "", auth: '', username: '', customer_id: '', customerorder_id: '', phone: '', bcipottery_token: '' });

  wishlistData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  storeWiseProductStock: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  reviewAfterOrder: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  cartOrderId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  couponVerify: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(readonly dataApiService: DataApiService, public router: Router
    // public commonService: CommonService
  ) {
    this.globalRouterLoaderShow = new BehaviorSubject(false);
    this.globalRouterLoaderShow_ = this.globalRouterLoaderShow.asObservable();
  }


  myOrderReview(payload: any) {
    this.reviewAfterOrder.next(payload);
  }
  getCartOrderId(payload: string) {
    this.cartOrderId.next(payload);
  }

  getCouponVerify(payload: string) {
    this.couponVerify.next(payload);
    // this.couponVerify.complete();
  }
  /**
   * Method to post login Based on username and password.
   * @param bodyobject object of key value;
   * @returns Observable of Category page.
   */
  postLogin(bodyobject: any): any {
    return this.dataApiService.postLogin(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }
  /**
   * Method to post register Based on user parameter.
   * @param bodyobject object of key value;
   * @returns Observable of Category page.
   */
  postRegister(bodyobject: any): any {
    return this.dataApiService.postRegister(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }
  /**
   * Method to post forgotpassword Based on user email/mobilenumber.
   * @param bodyobject object of key value;
   * @returns Observable of forgotpassword response.
   */
  postForgotpassword(bodyobject: any): any {
    return this.dataApiService.postForgotpassword(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }
  /**
   * Method to post forgotpassword Based on user email/mobilenumber.
   * @param bodyobject object of key value;
   * @returns Observable of forgotpassword response.
   */

  postResetpassword(bodyobject: any): any {
    return this.dataApiService.postResetpassword(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }
  /**
   * Method to post forgotpassword Based on user email/mobilenumber.
   * @param bodyobject object of key value;
   * @returns Observable of forgotpassword response.
   */
  postSetnewpassword(bodyobject: any): any {
    return this.dataApiService.postSetnewpassword(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }

  checkForgotPasswordLink(linkToken: any): any {
    return this.dataApiService.checkForgotPasswordLink(linkToken).pipe(
      map((data) => {
        return data;
      })
    );
  }
  /**
    * Method to set user credential into local starage and service.
    * @param res response from api;
    * @param isstaylogin options for logged in data store into local storage;
    */
  setUserCredentials(res: any, isstaylogin: boolean, passEncode?: any) {
    this.user = res.firstname + ' ' + res.lastname;
    this.userfirstname = res.firstname;
    this.userlastname = res.lastname;
    this.userId = res.id;
    this.isLoggedin = 'true';
    this.auth = res.token;
    this.username = res.username;
    this.customer_id = res.customer_id;
    this.bcipotteryToken = res.bcipottery_token;
    this.customerorder_id = localStorage.getItem('customerorder_id') ? localStorage.getItem('customerorder_id') : null;
    this.phone = res.userphonenumber;

    localStorage.setItem('user', this.user);
    localStorage.setItem('userfirstname', this.userfirstname);
    localStorage.setItem('userlastname', this.userlastname);
    localStorage.setItem('userId', this.userId);
    localStorage.setItem('isLoggedin', this.isLoggedin);
    localStorage.setItem('username', this.username);
    localStorage.setItem('customer_id', this.customer_id);
    localStorage.setItem('customerorder_id', this.customerorder_id);
    localStorage.setItem('phone', this.phone);
    localStorage.setItem('auth', this.auth);
    localStorage.setItem('isLoggedin', this.isLoggedin);
    localStorage.setItem('bcipottery_token', this.bcipotteryToken);
    localStorage.setItem('lastAction', JSON.stringify(Date.now()));

    if (isstaylogin) {
      let rememberObj: any = { Email: this.username, Password: passEncode }
      localStorage.setItem('rememberAuth', JSON.stringify(rememberObj));
    } else {
      localStorage.removeItem('rememberAuth');
    }
    this.authToken.next(Object.assign({ user: this.user, userfirstname: this.userfirstname, userlastname: this.userlastname, userId: this.userId, isLoggedin: this.isLoggedin, auth: this.auth, username: this.username, customer_id: this.customer_id, customerorder_id: this.customerorder_id, phone: this.phone, bcipottery_token: this.bcipotteryToken }));

  }

  onsetUserProfileModification(res: any) {
    this.user = res.firstname + ' ' + res.lastname;
    this.userfirstname = res.firstname;
    this.username = res.username;
    this.userlastname = res.lastname;
    this.phone = res.phone;

    this.authToken.next(Object.assign({ user: this.user, userfirstname: this.userfirstname, userlastname: this.userlastname, userId: this.userId, isLoggedin: this.isLoggedin, auth: this.auth, username: this.username, customer_id: this.customer_id, customerorder_id: this.customerorder_id, phone: this.phone, bcipottery_token: this.bcipotteryToken }));

  }

  /**
  * Method to get user credential from local starage to  service.
  */
  getUserCredentials() {
    if (localStorage.getItem('auth')) {
      this.user = localStorage.getItem('user');
      this.userId = localStorage.getItem('userId');
      this.isLoggedin = localStorage.getItem('isLoggedin');
      this.auth = localStorage.getItem('auth');
      this.username = localStorage.getItem('username');
      this.customer_id = localStorage.getItem('customer_id');
      this.customerorder_id = localStorage.getItem('customerorder_id');
      this.phone = localStorage.getItem('phone');
      this.username = localStorage.getItem('username');
      this.userfirstname = localStorage.getItem('userfirstname');
      this.userlastname = localStorage.getItem('userlastname');
      this.bcipotteryToken = localStorage.getItem('bcipottery_token');
    }

    this.authToken.next(Object.assign({ user: this.user, userfirstname: this.userfirstname, userlastname: this.userlastname, userId: this.userId, isLoggedin: this.isLoggedin, auth: this.auth, username: this.username, customer_id: this.customer_id, customerorder_id: this.customerorder_id, phone: this.phone, bcipottery_token: this.bcipotteryToken }));

  }
  /**
   * Method to remove user credential from local starage and change the value from service.
   */
  removeUserCredential() {
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('auth');
    localStorage.removeItem('username');
    localStorage.removeItem('customer_id');
    localStorage.removeItem('customerorder_id');
    localStorage.removeItem('userfirstname');
    localStorage.removeItem('userlastname');
    localStorage.removeItem('currentAccountUrl');
    localStorage.removeItem('phone');
    localStorage.removeItem('bcipottery_token');
    localStorage.removeItem('loginOnce');
    localStorage.removeItem('lastAction');
    if (this.router.url.includes('/cart') || this.router.url.includes('/checkout') || this.router.url.includes('/user')) {
      this.dataApiService.reloadCurrentRoute('/');
    }
    this.resetAuth()
  }


  // log-out
  removeUserCredential1() {
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('auth');
    localStorage.removeItem('username');
    localStorage.removeItem('customer_id');
    localStorage.removeItem('customerorder_id');
    localStorage.removeItem('userfirstname');
    localStorage.removeItem('userlastname');
    localStorage.removeItem('currentAccountUrl');
    localStorage.removeItem('phone');
    localStorage.removeItem('bcipottery_token');
    localStorage.removeItem('loginOnce');
    localStorage.removeItem('lastAction');
    localStorage.removeItem('filterlist');
    localStorage.removeItem('cartCount');
    localStorage.setItem("isLoggedin", "false");
    // Navigate to login component
    this.router.navigate(['log-in']);
    this.resetAuth()
  }

  resetAuth(){
    // Reset authToken BehaviorSubject
    this.authToken.next(Object.assign({ user: '', userfirstname: '', userlastname: '', userId: '', isLoggedin: 'false', auth: '', username: '', customer_id: '', customerorder_id: '', phone: '', bcipottery_token: '' }));
    this.user = '';
    this.userfirstname = '';
    this.userlastname = '';
    this.userId = '';
    this.isLoggedin = 'false';
    this.auth = '';
    this.username = '';
    this.customer_id = '';
    this.customerorder_id = '';
    this.phone = '';
    this.bcipotteryToken = '';
  }

  /**
   * Method to post my profile Based on user ID.
   * @param userid id of existing user;
   * @returns Observable of my profile response.
   */
  getmyprofile(userid: any): any {
    return this.dataApiService.getmyprofile(userid).pipe(
      map((data) => {
        return data;
      })
    );
  }
  putmyprofile(userid: any, bodyobject: any): any {
    return this.dataApiService.putmyprofile(userid, bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }

  setWishList(payload: any) {
    this.wishlistData.next(payload);
  }

  setStoreWiseProductStock(payload: any) {
    this.storeWiseProductStock.next(payload);
  }

  setGlobalRouterLaoder(payload: any) {
    this.globalRouterLoaderShow.next(payload);
  }

}
