import { Injectable, Inject, PLATFORM_ID, } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthguardGuard {
  constructor(
    private auth: AuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public dialog: MatDialog
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.isAccessUrl(state);
  }
  isAccessUrl(state: RouterStateSnapshot): Observable<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      const isLoggedIn = localStorage.getItem('isLoggedin') === 'true';

      if (isLoggedIn) {
        // User is logged in, allow access to the route
        return of(true);
      } else {
        // User is not logged in, redirect to the login page
        console.log('User is not logged in, navigating to login page');
        this.router.navigate(['log-in']);
        return of(false);
      }
    } else {
      return of(true);
    }
  }
}
