import { Injectable } from '@angular/core';
import { DataApiService } from '@app/core/services/http/data-api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CartService {
  private count: any = null;
  private totalAmount = 0;
  private cartItemArray: any[] = [];
  private totalDiscount = 0;
  private totalOfferAmount = 0;
  private total_tax = 0;
  storeChangedManually: boolean = false;
  poupCalled: boolean = false;
  constructor(private dataApiService: DataApiService) { }

  getCount() {
    return this.count;
  }

  setCount(count: number) {
    return this.count = count;
  }

  getTotalAmount() {
    return this.totalAmount;
  }

  setTotalAmount(totalAmount: number) {
    return this.totalAmount = totalAmount;
  }

  getTotalDiscount() {
    return this.totalDiscount;
  }

  setTotalDiscount(totalDiscount: number) {
    return this.totalDiscount = totalDiscount;
  }

  getTotalOfferAmount() {
    return this.totalOfferAmount;
  }

  setTotalOfferAmount(totalOfferAmount: number) {
    return this.totalOfferAmount = totalOfferAmount;
  }

  getTotalTaxAmount() {
    return this.total_tax;
  }

  setTotalTaxAmount(total_tax: number) {
    return this.total_tax = total_tax;
  }


  getCartItemArray() {
    return this.cartItemArray;
  }

  setCartItemArray(cartItemArray: any[]) {
    this.cartItemArray = [...cartItemArray];
    return this.cartItemArray;
  }
  
  getItemExist(itemId: number, item?: any, booking_data?: any) {
    const qualifierToCheck = item?.Item?.positemqualifier_id || item?.positemqualifier;
  
    const matchingCartItem = this.cartItemArray.find((x: any) =>
      +x.item_id === +item?.Item?.id &&
      (x.positemqualifier === qualifierToCheck) &&
      !("isAddonOf" in x) &&
      (
        (booking_data?.booking_id && +booking_data?.event_id === +this.getBookingData()?.event_id) ||
        (!booking_data?.booking_id)
      )
    );
  
    return matchingCartItem;
  }
  

  getItemCheck(itemId: number, item?: any) {
    return this.cartItemArray.find((x: any) => +x.item_id === +item.Item.id && (x.positemqualifier === item.Item.positemqualifier_id || x.positemqualifier === item.positemqualifier) && !("isAddonOf" in x));
  }
  booking_data: any = {}
  set setBookingData(data: any) {
    this.booking_data = data;
  }
   getBookingData() {
    return this.booking_data;
  }

  cartArray: any = [];
  // getCartDetails(cartData: any) {
  //   this.cartArray = cartData;

  // }
  cartDetails:any;
  setCartDetails(data:any) {
    this.cartDetails = data;
  }

  getCartDetails() {
    return this.cartDetails;
  }
  // This is for getting the reflection of cart data for checkout issue with store issue(Login & refresh Time) .
  public productsCartDuringLogin: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public cartDuringLogin: Observable<any> = this.productsCartDuringLogin.asObservable();

  setCartDuringLogin(data: any) {
    this.productsCartDuringLogin.next(data);
  }
  // This is for getting the reflection of cart data for checkout issue with store issue(Checkout Time) .
  public productsCartDuringCheckout: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public cartDuringCheckout: Observable<any> = this.productsCartDuringCheckout.asObservable();

  setCartDuringCheckout(data: any) {
    this.productsCartDuringCheckout.next(data);
  }

  allStoreList: any;

  setAllsoreData(data: any) {
    this.allStoreList = data;
  }
  getAllsoreData() {
    return this.allStoreList;
  }

  cartDataNew: any;
  setcartDataNew(data: any) {
    this.cartDataNew = data;
  }
  getcartDataNew() {
    return this.cartDataNew;
  }
  // This below variable is for checkout issue with store issue (N.B: - For restriction to the pop-up call!)
  callApiOnce: boolean = false;

  // This is for getting the reflection of cart data for checkout issue with store issue(Login & refresh Time) .
  public closingStoreBox: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public closingStoreBoxObs: Observable<any> = this.closingStoreBox.asObservable();

  setclosingStoreBox(data: boolean) {
    this.closingStoreBox.next(data);
  }


  giftcardOrderId: any;
  setGiftcardOrderId(id: any) {
    this.giftcardOrderId = id;
  }
  getGiftcardOrderId() {
    return this.giftcardOrderId;
  }
  addScript(url: string, type?: string, id?: string) {
    let chatScript = document.createElement("script");
    chatScript.type = type || "text/javascript";
    chatScript.async = true;
    chatScript.src = url;

    if (id) {
      chatScript.id = id;
    }

    document.body.appendChild(chatScript);
  }
  // This is for PIM-2159 inStock button on filter page .
  public isInStock: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public isInStockObs: Observable<any> = this.isInStock.asObservable();

  setIsInStock(data: any) {
    this.isInStock.next(data);
  }

  public iscartSet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public iscartSetObs: Observable<boolean> = this.iscartSet.asObservable();

  setIsCartSet(data: boolean) {
    this.iscartSet.next(data);
  }
}
