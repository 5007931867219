import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/design/material.module';
import { ReactiveFormsModule, Validators, FormGroup, FormControl, FormBuilder, AbstractControl, ValidationErrors, FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonService } from '@app/core/services/common.service';
@Component({
  selector: 'app-general-enquiry',
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  templateUrl: './general-enquiry.component.html',
  styleUrls: ['./general-enquiry.component.scss']
})
export class GeneralEnquiryComponent implements OnInit, OnDestroy {

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService
  ) { }

  private subscriptions: Subscription[] = [];
  profileForm: FormGroup;
  button_Loader: boolean = false;
  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      company_name: ['', Validators.required],  // Made required
      phone_number: ['', [Validators.required, Validators.pattern(/^\d/)]],  // Made required
      message: ['', Validators.required],  // Made required
    });
  }

  // Method to prevent the first character from being a space
 onInput_With_Number(event: any, formControlName: string): void {
  let inputValue = event.target.value.trimStart();
  const formControl = this.profileForm.get(formControlName);

  if (formControl) {
    formControl.setValue(inputValue, { emitEvent: false });

    if (inputValue === '') {
      formControl.setErrors({ required: true });
    } else {
      formControl.setErrors(null); // Clear any existing errors if the input is valid
    }

    formControl.markAsTouched();
    formControl.updateValueAndValidity();
  }
}

 // Method to prevent the first character from being a space
 onInput_Without_Number(event: any, formControlName: string): void {
  let inputValue = event.target.value.trimStart();
  // Remove any numbers from the input value
inputValue = inputValue.replace(/[0-9]/g, '');
  const formControl = this.profileForm.get(formControlName);

  if (formControl) {
    formControl.setValue(inputValue, { emitEvent: false });

    if (inputValue === '') {
      formControl.setErrors({ required: true });
    } else {
      formControl.setErrors(null); // Clear any existing errors if the input is valid
    }

    formControl.markAsTouched();
    formControl.updateValueAndValidity();
  }
}
  onSubmit(resetForm: FormGroupDirective) {
    this.button_Loader = true;
     // Mark all form fields as touched to trigger validation messages
     this.profileForm.markAllAsTouched();
    // Check if the form is invalid and stop further processing if it is
    if (this.profileForm.invalid) {
      this.button_Loader = false;
      return;
    }
    const postData = {
      type: "Contactus",
      message: {
        name: this.profileForm.get('name')?.value,
        email: this.profileForm.get('email')?.value,
        company_name: this.profileForm.get('company_name')?.value,
        phone_number: this.profileForm.get('phone_number')?.value,
        message: this.profileForm.get('message')?.value,
      }
    }
    const sub = this.commonService.general_enquiry_postData(postData).subscribe({
      next: (res) => {
        this.button_Loader = false;
       const success="Success";
        if (res.delivery_status === true) {
          this.commonService.openSnackbar(success, 20000, 'Dismiss');
          this.profileForm.reset();
          resetForm.resetForm();
        }
      },
      error: (error) => {
        this.button_Loader = false;
        let error_Message = 'Network error, please try again';
        if (error.status >= 400 && error.status <= 499) {
          let customErrorMessage = error?.error?.message;
          error_Message = customErrorMessage
            ? customErrorMessage
            : 'Error occurred, please try again';
        }
        this.commonService.openSnackbar(error_Message, 10000, 'Dismiss');
      }
    });
    this.subscriptions.push(sub);
  }

  onPhoneInput(event: any, formControlName: string): void {
    let inputValue = event.target.value.trimStart();

    // Replace non-numeric characters
    inputValue = inputValue.replace(/[^0-9]/g, '');

    const formControl = this.profileForm.get(formControlName);

    if (formControl) {
      formControl.setValue(inputValue, { emitEvent: false });

      if (inputValue === '') {
        formControl.setErrors({ required: true });
      } else {
        formControl.setErrors(null); // Clear any existing errors if the input is valid
      }

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
