import { Routes } from '@angular/router';
import { AuthguardGuard } from './core/guard/authguard.guard';
import { PageNotFoundComponent } from './misc/layout/page-not-found/page-not-found.component';
import { GeneralEnquiryComponent } from './misc/general-enquiry/general-enquiry.component';
export const routes: Routes = [
  {
    path: "forget-password",
    loadChildren: () =>
      import("./core/helper/const-routes").then((mod) => mod.FORGET_PASSWORDS_ROUTES),
  },

  //reset password
  {
    path: "reset-password",
    loadChildren: () =>
      import("./core/helper/const-routes").then((mod) => mod.RESET_PASSWORDS_ROUTES),
  },

//  general enquiry
{
  path: "general-enquiry",
  component: GeneralEnquiryComponent
},

  {
    path: "log-in",
    loadChildren: () =>
    import("./core/helper/const-routes").then((mod) => mod.LOGIN_ROUTES),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./core/helper/const-routes").then((mod) => mod.REGISTER_ROUTES),
  },
  {
    path: "user",
    loadChildren: () =>
      import("./core/helper/const-routes").then((mod) => mod.USER_ROUTES),
    canActivate: [AuthguardGuard]
  },
  {
    path: 'listing',
    loadChildren: () =>
      import("./core/helper/const-routes").then((mod) => mod.LISTING_ROUTES),
  },
  {
    path: "cart",
    loadChildren: () =>
      import("./core/helper/const-routes").then((mod) => mod.CART_ROUTES),
    canActivate: [AuthguardGuard]
  },
  {
    path: "order",
    loadChildren: () =>
      import("./core/helper/const-routes").then((mod) => mod.ORDER_EDIT),
    canActivate: [AuthguardGuard]
  },
  {
    path: "checkout",
    loadChildren: () =>
      import("./core/helper/const-routes").then((mod) => mod.CHECKOUT_ROUTES),
    canActivate: [AuthguardGuard]
  },
  {
    path: "order-process",
    loadChildren: () =>
      import("./core/helper/const-routes").then((mod) => mod.ORDER_PROCESS_ROUTES),
    canActivate: [AuthguardGuard]
  },
  {
    path: 'product/:slug',
    canActivate: [AuthguardGuard],
    loadChildren: () =>
      import("./core/helper/const-routes").then((mod) => mod.DETAILS_ROUTES),
  },

  {
    path: 'design',
    loadChildren: () => import('./core/helper/constants/routes').then((m) => m.DesignRoutes),
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent
  },
  {
    path: ':slug',
    loadComponent: () =>
      import("./publish/publish.component").then(
        (x) => x.PublishComponent
      ),
  },
  {
    path: '',
    loadComponent: () =>
      import("./publish/publish.component").then(
        (x) => x.PublishComponent
      ),
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];
