<footer>
  <div class="footer-inner-section">
    <div class="top-section">
      <ul>
        <li>products</li>
        <li *ngFor="let item of footerList?.sectionTwo?.customServiceArray">
          <a [href]="item.url">{{ item.label }}</a>
        </li>
      </ul>
      <ul>
        <li>company info</li>
        <li *ngFor="let item of footerList?.sectionTwo?.aboutUsArray">
          <a [href]="item.url">{{ item.label }}</a>
        </li>
      </ul>
      <ul>
        <li>account</li>
        <li *ngFor="let item of footerList?.sectionTwo?.resourcesArray">
          <a (click)="navigateToUrlr(item.url, item.label)">{{ item.label }}</a>
        </li>
      </ul>
      <ul>
        <li>contact us</li>
        <li *ngFor="let item of footerList?.sectionTwo?.findUsArray">
          <a [href]="item.url">{{ item.label }}</a>
        </li>
      </ul>
    </div>
    <div class="bottom-section">
      <div class="left">
        <ul>
          <li>{{ footerList?.sectionFour?.leftSide?.copyrightText }}</li>
          <li
            *ngFor="
              let item of footerList?.sectionFour?.rightSide?.rightSideArray
            "
          >
            <a [href]="item.url">{{ item.label }}</a>
          </li>
        </ul>
      </div>
      <div class="right">
        <ul>
          <li>
            <a href="http://borderconcepts.com/">
              <img style="width: 65px; cursor: pointer; top: -19px;
              position: relative;
              height: 65px;" alt="" src="https://d1if7r1bmiltgp.cloudfront.net/assets/images/bcipotterydirect_logo.png">
            </a>
          </li>
          <li style="padding-left: 25px;">
            <a
              [href]="footerList?.sectionTwo?.connectGroup?.facebookGroup?.url" target="_blank"
            >
              <i class="icon-facebook"></i>
            </a>
          </li>
          <li>
            <a
              [href]="footerList?.sectionTwo?.connectGroup?.pinterestGroup?.url" target="_blank"
            >
              <i class="icon-instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/border-concepts-inc" target="_blank">
              <i class="icon-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-mobile">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title style="text-transform: uppercase;">products</mat-panel-title>
          </mat-expansion-panel-header>
          <ul>
            <li *ngFor="let item of footerList?.sectionTwo?.customServiceArray">
              <a [href]="item.url">{{ item.label }}</a>
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title style="text-transform: uppercase;">company info</mat-panel-title>
           </mat-expansion-panel-header>
          <ul>
            <li *ngFor="let item of footerList?.sectionTwo?.aboutUsArray">
              <a [href]="item.url">{{ item.label }}</a>
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title style="text-transform: uppercase;">account</mat-panel-title>
           </mat-expansion-panel-header>
          <ul>
            <li *ngFor="let item of footerList?.sectionTwo?.resourcesArray">
              <a (click)="navigateToUrlr(item.url, item.label)">{{ item.label }}</a>
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title style="text-transform: uppercase;">contact us</mat-panel-title>
           </mat-expansion-panel-header>
          <ul>
            <li *ngFor="let item of footerList?.sectionTwo?.findUsArray">
              <a [href]="item.url">{{ item.label }}</a>
            </li>
          </ul>
        </mat-expansion-panel>

      </mat-accordion>
    </div>
  </div>
</footer>
