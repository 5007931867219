import { CommonModule, NgIf, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { CommonService } from './core/services/common.service';
import { environment } from "../environments/environment";
import { AutoLogoutService } from './core/services/auto-logout.service';
import { LayoutComponent } from './misc/layout/layout/layout.component';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
declare global {
  interface Window {
    stipeKey: string;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    LayoutComponent,
    RouterOutlet,
    NgIf,
    RouterLink,
    CommonModule,
    HttpClientModule,
    MatSnackBarModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  title = 'bcipotterydirect-frontend-angular';
  showLoader: boolean;
  loaderSubscription: any;
  isLoggedIn = JSON.parse(localStorage.getItem('isLoggedin'));

  constructor(public commonService: CommonService, private auth: AuthService, private router: Router, @Inject(PLATFORM_ID) private platformId: Object, private renderer: Renderer2, public autoLogout: AutoLogoutService) {
    window.stipeKey = environment.stipePublicKey;
    this.showLoader = false;
    this.initializeduserinformation();
    //this.auth.ondefaultsetSessionstorage();

  }
  handleNavigationEndEvent(router: Router, platformId: Object): void {
    if (router.url === '/about-us/welcome-to-bcipottery') {
      router.navigateByUrl('about-us');
    }

    if (isPlatformBrowser(platformId) && !router.url.startsWith(window.location.pathname)) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  handleCheckoutEvent(event: any, router: Router): void {
    if (event.id === 1 && event.url === '/checkout/checkout-billing') {
      router.navigate(['/checkout/order-review']);
    }
  }

  toggleSearchBar(): void {
    const searchBarContainer = document.getElementsByClassName('searchBar') as HTMLCollectionOf<HTMLElement>;
    if (searchBarContainer.length > 1 && searchBarContainer[1].classList.contains('active')) {
      searchBarContainer[1].classList.toggle('active');
    } else if (searchBarContainer.length > 1 && searchBarContainer[0].classList.contains('active')) {
      searchBarContainer[0].classList.toggle('active');
    }
  }

  handleJsonLdPusher(): void {
    if (!environment.isDevServer) {
      this.jsonLdPusher();
    }
  }

  handleRedirectedUrl(event: any, renderer: Renderer2): void {
    if (event.hasOwnProperty('urlAfterRedirects')) {
      let loader = renderer.selectRootElement('#loading');
      renderer.setStyle(loader, 'display', 'none');
    }
  }
  initializeduserinformation() {
    this.auth.getUserCredentials();
  }

  ngAfterViewInit(): void {
    this.loaderSubscription = this.commonService.globalRouterLoaderShow.subscribe(
      res => {
        this.showLoader = res;
      }
    )
  }

  ngOnDestroy(): void {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }
  jsonLdPusher() {
    const jsonLdData = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "bcipotterys",
      "url": "https://www.bcipotterys.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://www.bcipotterys.com/listing?search={search_term_string}",
        "query-input": "required name=search_term_string"
      },
      "description": "Welcome to Border Concept- Pottery",
      "image": {
        "@type": "ImageObject",
        "url": "https://images.bcipotterys.com/assets/images/logo.png",
        "width": 290,
        "height": 60
      },
      "sameAs": [
        "https://www.facebook.com/bcipotterystores/",
        "https://twitter.com/bcipotterystores?lang=en"
      ]
    };
    // Find the existing JSON-LD script element
    // const existingJsonLd = document.querySelector('script[type="application/ld+json"]');
    const existingJsonLd = document.querySelector('#home');
    // If an existing JSON-LD script element was found, remove it from the DOM
    if (!existingJsonLd) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.id = 'home';
      script.text = JSON.stringify(jsonLdData);
      this.renderer.appendChild(document.head, script);
    }


  }
}
