import { Component, OnInit, DoCheck, EventEmitter, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { UtilityService } from '../../core/services/utility.service';
import { combineLatest, Subject, Observable } from 'rxjs';
import { switchMap, debounceTime } from 'rxjs/operators';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports:[
    MatIconModule,
    MatFormFieldModule,
    NgIf,
    FormsModule,
    MatInputModule,
    NgClass,
    NgFor,
    MatButtonModule,
    NgxSkeletonLoaderModule
  ]
})
export class SearchComponent implements OnInit {
  isLoader: boolean = false;
  searchText: string = '';
  searchLimit = 4;
  searchOffset = 0;
  totalData = 0;
  searchDataList:any=[];
  isLoggedin = JSON.parse(localStorage.getItem('isLoggedin'));

  @Output() isMegaMenuVisible = new EventEmitter<any>();

  private _searchingItem: boolean = false;
  // Getter
  get searchingItem(): boolean {
    return this._searchingItem;
  }

  // Setter
  @Input() set searchingItem(value: boolean) {
    if(value){
      this._searchingItem = value;
      this.getGlobalSearchDataList();
    }
  }

  constructor(public commonService: CommonService, public router: Router, private activatedRoute: ActivatedRoute, public utilityService: UtilityService, public analyticsService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    document.getElementById("search").focus();
  }
  onInputChange(): void {
    if (this.searchText.length > 2) {
      this.getGlobalSearchDataList();
    }
  }

  getGlobalSearchDataList(){
    this.isLoader = true;
    this.searchDataList = [];
    this.commonService.getProductList(this.searchLimit,this.searchOffset,null,this.searchText).subscribe((res:any)=>{
      this.isLoader = false;
      this.searchDataList= res.data;
    })
  }

  triggerEvent() {
    this.isMegaMenuVisible.emit(false);
  }

  getimageUrl(imageName:any, moduleName:any, timestamp:any){
    return this.utilityService.getImageData(imageName, moduleName, 70, 70, null, timestamp)
  }

  getAssetsImageUrl(imageName: string, moduleName: string,){
    return this.utilityService.getAssetsImage(imageName, moduleName);
  }

  listingRedirect(){
    this.commonService.searchText.next(this.searchText);
    this.isMegaMenuVisible.emit(false);
    this.router.navigateByUrl(`/listing?search=${this.searchText}`);
    this.triggerEvent();
  }

  orderProducts(data: any) {
    localStorage.removeItem('pallet_type')
    if (this.isLoggedin) {
      this.router.navigate([`product/${data.slug}`])
      this.triggerEvent();
    } else {
      let currentUrl = `product/${data.slug}`;
      localStorage.setItem('prevURL', currentUrl);
      this.router.navigate(['log-in'])
    }
  }
}
