
<div class="pop-up-body show">
    <div class="pop-box plate-count">
      <div class="pop-head">
        {{ data.title }}
      </div>

      <div mat-dialog-content>
        <div class="row-cus">
              <div class="lablerow" style="padding: 10px 20px 0px 35px;">{{ data.message }}</div>
        </div>
      </div>
      <div class="bottom blk">
        <button class="yellow-btn" type="button" (click)="closeDialog()">{{ data.confirmText }}</button>
        <button class=" primary-base-btn" type="button" (click)="onNoClick()">{{ data.cancelText }}</button>
      </div>
    </div>
  </div>

