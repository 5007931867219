import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  navMenu:any[] = [];

  constructor(readonly http: HttpClient) { }

  getItemGroupLevelById(id: any) {
    const data:any = this.navMenu.find(menu=>menu.navigationitem_value==id);
    return data?.navigationitem_label;
  }

  navItemList(id:number): Observable<any> {
    return this.http.get(`api/v1/navitem/?businessunitid=${id}`);
  }

  navFooterList(id:number): Observable<any> {
    return this.http.get(`api/v1/navpublicfooter/?businessunitid=${id}`);
  }
}
