<!-- top stepper -->
<section class="top-steper-section">
  <div class="inner-section">
    <ul>
      <li>
        <a href="#">Home</a>
      </li>
      <li>
        <a class="active" href="#">Contact Us</a>
      </li>
    </ul>
  </div>
</section>
<!-- top stepper -->
<div class="containerr">
  <div class="image-section">
    <img alt="" src="https://d1if7r1bmiltgp.cloudfront.net/images/productimages/fit-in/650x665/40030_20240703164902.jpg" />
  </div>

    
      <div class="login-box registration">
        <h2>General inquiries</h2>
        <div class="login-box-inner">
          <form [formGroup]="profileForm" (ngSubmit)="onSubmit(form)" #form="ngForm">
            <div class="row-cus">
              <div class="col-50">
                <div class="lablerow">Your Name <span>*</span></div>
                <mat-form-field appearance="outline">
                  <input matInput placeholder="Your Name" type="text" formControlName="name"  (input)="onInput_Without_Number($event, 'name')"  required />
                  <!-- Add a validation message for the required field -->
              <mat-error *ngIf="profileForm.get('name').hasError('required') ">
                Name is required
              </mat-error>
                </mat-form-field>
              </div>
              <div class="col-50">
                <div class="lablerow">Email Address <span>*</span></div>
                <mat-form-field appearance="outline">
                  <input matInput placeholder="Email Address" type="email" formControlName="email" (input)="onInput_With_Number($event, 'email')" required/>
                       <!-- Add a validation message for the required field -->
              <mat-error *ngIf="profileForm.get('email').hasError('required') ">
                Email is required
              </mat-error>
                <!-- Error message for the email format validation -->
                <mat-error *ngIf="profileForm.get('email').hasError('email')">
                    Please enter a valid email address
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row-cus">
              <div class="col-50">
                <div class="lablerow">Company Name <span>*</span></div>
                <mat-form-field appearance="outline">
                  <input matInput placeholder="Company Name" type="text" formControlName="company_name" (input)="onInput_With_Number($event, 'company_name')" required />
              <mat-error *ngIf="profileForm.get('company_name').hasError('required')">
                Company name is required
              </mat-error>
                </mat-form-field>
              </div>

              <div class="col-50">
                <div class="lablerow">Phone Number <span>*</span></div>
                <mat-form-field appearance="outline">
                  <input matInput placeholder="Phone Number" type= "text" formControlName="phone_number" (input)="onPhoneInput($event, 'phone_number')" required />
                  <mat-error *ngIf="profileForm.get('phone_number').invalid">
                    <span *ngIf="profileForm.get('phone_number').errors.required"
                      >Phone is required.</span
                    >
                    <span *ngIf="profileForm.get('phone_number').errors.pattern"
                      >Please enter a valid phone number.</span
                    >
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row-cus">
              <div class="col-full text-area">
                <div class="lablerow">Message Text <span>*</span></div>
                <mat-form-field appearance="outline" class="full-width text-area-custom">         
                  <input matInput placeholder="Message text" type= "text" formControlName="message" (input)="onInput_With_Number($event, 'message')" required >
                  <mat-error *ngIf="profileForm.get('message').hasError('required')">
                    Please provide some message
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row-cus">
              <div class="col-full">
                <button class="yellow-btn loder-btn" type="submit">
                  <div class="loder" *ngIf="button_Loader">
                    <img alt="" src="https://potterydirect.s3.amazonaws.com/assets/images/loader-sml.gif">
                  </div>  
                  <b>Send Message</b>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
</div>
