import { NgFor, NgIf, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { MaterialModule } from '@app/design/material.module';
import { NavService } from '@app/shared/service/nav.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, MaterialModule]
})
export class FooterComponent implements OnInit{

  footerList: any;
  businessunitid:any = 4
  isLoggedIn: boolean = true;

  constructor(private navService: NavService, @Inject(PLATFORM_ID) private platformId: Object, private commonService: CommonService, public router: Router) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getFooter(this.businessunitid)
    }
    this.commonService.isLoggedIn$.subscribe(res => {
      this.isLoggedIn = res;
      this.getFooter(this.businessunitid)
    })
  }


  getFooter(id: number) {
    this.navService.navFooterList(id).subscribe(res => {
      this.footerList = JSON.parse(res[0]?.data[0]?.footer_json)
      if(this.isLoggedIn == true){
        this.footerList.sectionTwo.resourcesArray = this.footerList.sectionTwo?.resourcesArray.filter(item => item.label !== 'Sign In' && item.label !== 'Create Account');
      }else{
        this.footerList = this.footerList;
      }
    })
  }

  navigateToUrlr(url: string, label: string){
    let route: string = url.split('/')[url.split('/').length - 1];
    if(label == 'Wishlist' || label == 'My Account'){
      this.router.navigate([`user/${route}`]);
    }else{
      this.router.navigate([`${route}`]);
    }
  }
}
