import { Component,Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-dailog',
  standalone: true,
  imports: [CommonModule,MatButtonModule],
  templateUrl: './delete-dailog.component.html',
  styleUrls: ['./delete-dailog.component.scss']
})
export class DeleteDailogComponent {

  constructor(
    private router : Router,
    public dialogRef: MatDialogRef<DeleteDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  closeDialog(): void {
    this.dialogRef.close(true);
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }
}
