<div class="mega-search-innerwrap">
  <div class="input-outerbox">
      <div class="search-con"><i class="icon-search"></i></div>
      <input type="text" id="search" [(ngModel)]="searchText" (input)="onInputChange()" (keyup.enter)="listingRedirect()" focus>
      <button (click)="triggerEvent()">
          Close <i class="icon-cross"></i>
      </button>
  </div>
  <div class="mega-search-content">
      <div class="head-sec">
          <span>Products</span>
          <a (click)="listingRedirect()" style="cursor: pointer;">See all <i class="icon-next"></i></a>
      </div>

      <div class="search-row" *ngIf="isLoader">
        <ngx-skeleton-loader
          count="4"
          appearance="rectangle"
          [theme]="{
            width: '600px',
            height: '70px',
            'margin': '5px 0px'
          }"
        >
        </ngx-skeleton-loader>
      </div>

      <div class="search-row pointer-button" *ngFor="let product of searchDataList">
          <div class="image-box" (click)="orderProducts(product.data[0])">
            <ng-container>
              <img alt="" 
              [src]="product.data[0].image[0] ? getimageUrl(product.data[0].image[0]?.image,'images/productimages',product.data[0].image[0]?.image_timestamp) : getAssetsImageUrl('bci_noimage.png','assets/images')">
            </ng-container>
          </div>
          <div class="right-name-section" (click)="orderProducts(product.data[0])">
              <p>{{product.product_name}}</p>
              <span>{{product.item_identifier}}</span>
          </div>
      </div>

  </div>

  <div class="loader">
  </div>

</div>
