import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms_and_condition',
  standalone: true,
  templateUrl: './terms_and_condition.component.html',
  imports: [CommonModule,MatButtonModule],
  styleUrls: ['./terms_and_condition.component.css']
})

export class Terms_and_conditionComponent {

  constructor(
    private router : Router,
    public dialogRef: MatDialogRef<Terms_and_conditionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
  ) {}


  closeDialog(data:boolean): void {
    this.dialogRef.close(data);
  }

}

