import { Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
declare var gtag: Function;

declare global {
	interface Window { dataLayer: any[]; }
}


@Injectable({
	providedIn: 'root'
})
export class GoogleAnalyticsService {

	constructor() { }

	/**
	   * dataLayerPushItems() --> This method receives products data from various pages
	   */
	dataLayerPushItems(data: any, url: string, page: string) {
		if (data.length > 0) {
			switch (page) {
				case 'listing':
					this.DoItForItems(data, 'listing');
					break;
				case 'landing':
					this.DoItForItems(data, 'landing');
					break;
				default:
				// code block for default case!!
			}
		}
	}


	/**
	   * DoItForItems() is a common function for pushing the datas to dataLayer.
	   *
	   */
	DoItForItems(dataLayerArray: any, type: string) {
		let datalayerItems: any = [];
		dataLayerArray.forEach((b: any, i: number) => {
			// dataLayerArray[j].forEach((b: any, i: number) => {
			datalayerItems.push({
				'item_id': b.Item.id,
				'item_name':b.Item.name,
				'price': b.price ?? b.regularPrice,
				'item_brand': b.brand_name ?? b.Item.brand_name ?? "NA",
				'item_category': b.primarytaxonomy.slice(-1)[0].taxnomyname ?? '',
				'item_variant': 'NA',
				'item_list_name': type,
				'index': i
			});

			// });
		});
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			'event': 'view_item_list',
			"ecommerce": {
				"currency": "USD",
				"page_name": type,
				"items": datalayerItems,
			}
		});
	}
	forSingleItemCollection(data: any) {
		if (Object.keys(data).length > 0) {
			window['dataLayer'] = window['dataLayer'] || [];
			window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
			window.dataLayer.push({
				'item_id': data.Item.id,
				'price': data.price,
				'item_brand': data.brand_name ?? 'NA',
				'item_category': data.primarytaxonomy.slice(-1)[0].taxnomyname ?? '',
				'item_variant': 'NA',
				'item_list': 'landing',
				'index': 0
			});
		}
	}
	/**
	 * Call this function when a user clicks on a product link. This function uses the event
	 * callback datalayer variable to handle navigation after the ecommerce data has been sent
	 * to Google Analytics.
	 * @param {Object} productObj An object representing a product.
	 */
	productClick(productObj: any) {
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'event': 'select_item',
			'ecommerce': {
				// 'click': {     // Optional list property.
					'items': [{
						'item_name': productObj.Item.name,                      // Name or ID is required.
						'item_id': productObj.Item.id,
						'price': productObj.price,
						'item_brand': productObj.Item.brand ?? '',
						'item_category': productObj?.primarytaxonomy?.slice(-1)[0].taxnomyname ?? '',
						'item_variant_color': productObj.Item.is_variant_color == 1 ? true : false,
						'item_variant_size': productObj.Item.is_variant_size == 1 ? true : false,
					}]
				// }
			},
		});
	}

	productView(productObj: any) {
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'event': 'view_item',
			'ecommerce': {
				// 'click': {
					currency: "USD",
					value: productObj.price,
					'items': [{
						'item_name': productObj.Item.name,
						'item_id': productObj.Item.id,
						'price': productObj.price,
						'item_brand': productObj.Item.brand ?? '',
						'item_category': productObj?.primarytaxonomy?.slice(-1)[0].taxnomyname ?? '',
					}]
				// }
			},
		});
	}

	addToCart(productObj: any) {
		// Measure adding a product to a shopping cart by using an 'add' actionFieldObject
		// and a list of productFieldObjects.
		window['dataLayer'] = window['dataLayer'] || [];
		if (productObj?.primarytaxonomy?.slice(-1)[0].taxnomyname !== undefined) window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'event': 'add_to_cart',
			'ecommerce': {
				'currency': 'USD',
				'value': productObj.price * productObj.itemCount,
				// 'add': {                                // 'add' actionFieldObject measures.
					'items': [{                        //  adding a product to a shopping cart.
						'item_name': productObj.Item.name,                      // Name or ID is required.
						'item_id': productObj.Item.id,
						'price': productObj.price,
						'item_brand': productObj.Item.brand ?? '',
						'item_category': productObj?.primarytaxonomy?.slice(-1)[0].taxnomyname ?? '',
						'quantity': productObj.itemCount
					}]
				// }
			}
		});
	}

	removeFromCart(productObj: any) {
		// Measure the removal of a product from a shopping cart.
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'event': 'remove_from_cart',
			'ecommerce': {
				currency: "USD",
				value: productObj.price,
				// 'remove': {                               // 'remove' actionFieldObject measures.
					'items': [{                          //  removing a product to a shopping cart.
						'item_name': productObj.Item.name,                      // Name or ID is required.
						'item_id': productObj.Item.id,
						'price': productObj.price,
						'item_brand': productObj.Item.brand ?? '',
						'item_category': productObj?.primarytaxonomy?.slice(-1)[0].taxnomyname ?? '',
						'quantity': productObj?.itemCount == 0 ? 1 : productObj?.itemCount
					}]
				// }
			}
		});
	}

	promoView(dataObject: any) {
		// An example of measuring promotion views. This example assumes that
		// information about the promotions displayed is available when the page loads.
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'ecommerce': {
				'promoView': {
					'promotions': [                     // Array of promoFieldObjects.
						{
							'id': 'JUNE_PROMO13',            // ID or Name is required.
							'name': 'June Sale',
							'creative': 'banner1',
							'position': 'slot1'
						},
						{
							'id': 'FREE_SHIP13',
							'name': 'Free Shipping Promo',
							'creative': 'skyscraper1',
							'position': 'slot2'
						}]
				}
			}
		});
	}

	checkout(dataObject: any) {
		/**
 * A function to handle a click on a checkout button. This function uses the eventCallback
 * data layer variable to handle navigation after the ecommerce data has been sent to Google Analytics.
 */
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		let datalayerItems: any = [];
		const data = dataObject.data;
		data.forEach((b: any, i: number) => {
			datalayerItems.push({
				'item_name': b.Item.name,                      // Name or ID is required.
				'item_id': b.Item.id,
				'price': b.price,
				'item_brand': b.Item.brand ?? '',
				'item_category': b?.primarytaxonomy?.slice(-1)[0].taxnomyname ?? '',
				'quantity': b?.itemCount,
				'index': i
			});
		});
		window.dataLayer.push({
			'event': 'begin_checkout',
			'ecommerce': {
				// 'checkout': {
					currency: "USD",
					value: dataObject.total,
					tax: dataObject.tax,
					'actionField': { 'step': dataObject.step, 'option': 'Card' },
					'items': datalayerItems
				// }
			}
		});
	}
	addToWishlist(dataObject: any) {
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });
		let datalayerItems: any = [];
		const data = [dataObject];
		data.forEach((b: any, i: number) => {
			datalayerItems.push({
				'item_name': b.Item.name,
				'item_id': b.Item.id,
				'price': b.price,
				'item_brand': b.Item.brand ?? '',
				'item_category': b?.primarytaxonomy?.slice(-1)[0].taxnomyname ?? '',
				'quantity': b?.itemCount,
				'index': i
			});
		});
		window.dataLayer.push({
			'event': 'add_to_wishlist',
			'ecommerce': {
				'items': datalayerItems
			}
		});
	}
	addPaymentInfo(dataObject: any) {
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		let datalayerItems: any = [];
		const data = dataObject.data;
		data.forEach((b: any, i: number) => {
			datalayerItems.push({
				'name': b.Item.name,                      // Name or ID is required.
				'id': b.Item.id,
				'price': b.price,
				'brand': b.Item.brand ?? '',
				'category': b?.primarytaxonomy?.slice(-1)[0].taxnomyname ?? '',
				'quantity': b?.itemCount,
				'position': i + 1
			});
		});
		window.dataLayer.push({
			'event': 'add_payment_info',
			'ecommerce': {
				currency: "USD",
				value: 7.77,
				coupon: "SUMMER_FUN",
				payment_type: "Credit Card",
				'products': datalayerItems
			}
		});
	}
	search(data: any) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'event': 'search',
			'ecommerce': {
				'search_term': data
			}
		});
	}

	purchase(dataObject: any) {
		// Send transaction data with a pageview if available
		// when the page loads. Otherwise, use an event when the transaction
		// data becomes available.
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		let datalayerItems: any = [];
		const data = dataObject.data;
		data.forEach((b: any, i: number) => {
			datalayerItems.push({
				'item_name': b.Item.name,                      // Name or ID is required.
				'item_id': b.Item.id,
				'price': b.price,
				'item_brand': b.Item.brand ?? '',
				'item_category': b?.primarytaxonomy?.slice(-1)[0].taxnomyname ?? '',
				'quantity': b?.itemCount,
				'index': i
			});
		});
		window.dataLayer.push({
			'event': 'purchase',
			'ecommerce': {
				'purchase': {
					'transaction_id': dataObject?.transaction_id,
					'affiliation': dataObject?.store,
					'revenue': dataObject?.sub_total,
					'tax': dataObject?.tax,
					'coupon': dataObject?.coupon,
					'value': dataObject?.sub_total,
					'currency': 'USD',
					'items': datalayerItems
				}
			}
		});

	}

	refund(data: any) {
		// Refund an entire transaction by providing the transaction ID. This example assumes the details
		// of the completed refund are available when the page loads:
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer.push({
			'ecommerce': {
				'refund': {
					'actionField': { 'id': 'T12345' }         // Transaction ID. Required for purchases and refunds.
				}
			}
		});
	}


	sendPageview(page: string, title: string, type?: string) {
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: 'page_view',
			pagePath: page,
			pageType: type ? type : '',
			pageTitle: title,
		});
	}

	sendEvent(event: string, data?: any) {
		window['dataLayer'] = window['dataLayer'] || [];
		let obj = {
			event: event,
			...data
		};
		window.dataLayer.push(obj);
	}

	locationFlyoutClicked(data: any, locationUri: any,allStores:number = 0) {
		// const isLoggedin = localStorage.getItem('isLoggedin');
		if (data) {
			const locationUrl = `http://maps.google.com/?q=${locationUri}`;
			let user = localStorage.getItem('userfirstname') + ' ' + localStorage.getItem('userlastname');
			if(!localStorage.getItem('userfirstname')) user = 'Guest User';
			window['dataLayer'] = window['dataLayer'] || [];
			window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push({
				event: 'location_flyout_click',
				'user': user ,
				'sum_of_all_stores': allStores,
				'store_name': data.storename,
				'location_url': locationUrl,
				'address': data.address ?? '',
				'city': data.city ?? '',
				'state': data.state_name ?? '',
				'zip': data.zipcode ?? ''
			});
		}
	}

	registraionSuccess(data:any) {
		const user = data.consumer_name ||  data.firstname + ' ' + data.lastname;
		window['dataLayer'] = window['dataLayer'] || [];
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: 'registration-success',
			ecommerce: {
				user: user,
			    email: data.email ?? '',
			}
		});
	}
}
